import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, IcoArchive, IcoDownload, PopoverCentralAjuda } from '../../../components';
import { CustomLabel, CustomTooltip, RenderLegend, graficoOptions } from './graficoOptions';
import { Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { PageLoadView, convertChart } from '../../../helpers';
import { CustomTickNew } from '../customticknew';

export default ({ nlsContexto, imprimir }) => {
  const dispatch = useDispatch()
  const { grafico, graficoLoad } = useSelector(state=> state.npsconcorrentesredesState)
  const { titlePage, sistema } = useSelector(state=> state.layoutState)
  const [download, setDownload] = useState(false)

  const nls = require(`./nls/${sistema.idioma}.json`)

  const idarray = [215];

  const dadosgerais = nls.geral;


  const handleDownload = async (e) => {
    setDownload(true)
    const chartdownload = await convertChart({id: 'graficos-chart-box', titlePage})
    setDownload(chartdownload)
  }

  return (
    <>
      <div id='graficos-chart-box' className={`box-npsconcorrentesredes-graficos box-content ${download?'graficos-download':''}`}>
        <div className='graficos-chart-box-action'>
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
          {!graficoLoad?<Button type='btn circle' color='primary' variant='outlined' onClick={()=> handleDownload()} title='Download gráfico'><IcoDownload /></Button>:null}
        </div>
        <div id='graficos-chart' className="box-npsconcorrentesredes-graficos-chart">
          <h3>{titlePage}</h3>
          <PageLoadView
           load={graficoLoad} 
           data={grafico.data}
           semdadosparaexibir={!imprimir ? nls.semdadosparaexibir : null}
           >
            {imprimir ? (
            <ResponsiveContainer width="100%" height="100%" >
              <BarChart
                width={graficoOptions.BarChart.width}
                height={graficoOptions.BarChart.height}
                data={grafico.data}
                margin={graficoOptions.BarChart.margin}
              >
                <CartesianGrid
                  stroke={graficoOptions.CartesianGrid.stroke} 
                  vertical={graficoOptions.CartesianGrid.vertical} 
                />
                {
                  grafico.data && !(grafico.labels.length <= graficoOptions.Brush.endIndex)?
                  <Brush
                    dataKey={graficoOptions.Brush.dataKey} 
                    height={graficoOptions.Brush.height} 
                    stroke={graficoOptions.Brush.stroke} 
                    startIndex={graficoOptions.Brush.startIndex}
                    endIndex={graficoOptions.Brush.endIndex}
                  />
                  :null
                }
                <XAxis 
                  dataKey={graficoOptions.XAxis.dataKey} 
                  stroke={graficoOptions.XAxis.stroke} 
                  axisLine={graficoOptions.XAxis.axisLine} 
                  tickLine={graficoOptions.XAxis.tickLine} 
                  fontSize={graficoOptions.XAxis.fontSize} 
                  tick={(props) => <CustomTickNew {...props} dadosgerais={dadosgerais} />}
                />
                <YAxis 
                  unit={graficoOptions.YAxis.unit} 
                  stroke={graficoOptions.YAxis.stroke} 
                  axisLine={graficoOptions.YAxis.axisLine} 
                  tickLine={graficoOptions.YAxis.tickLine} 
                  fontSize={graficoOptions.YAxis.fontSize} 
                />
                <Tooltip content={<CustomTooltip labels={grafico.labels} msgNA={nls.Dadonaodisponivel}/>} />
                <Legend content={<RenderLegend  labels={grafico.labels} />} />
                {
                  grafico.labels?.map((label, i)=> <Bar 
                    key={`bar-${label.type}-${i}`}
                    dataKey={label.type} 
                    fill={label.color} 
                    radius={graficoOptions.Bar.radius} 
                    label={<CustomLabel />}
                  />)
                }
              </BarChart>
            </ResponsiveContainer>
          ) : null}
          </PageLoadView>
        </div>
      </div>
      {
        imprimir && grafico.analiseGrafica?
        <div className='box-npsconcorrentesredes-analise-grafica box-content'>
          <h3><IcoArchive/> {nls.Analise}</h3>
          <div dangerouslySetInnerHTML={{__html: grafico.analiseGrafica}}></div>
        </div>
        :null
      }
    </>
  )
}
