import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal, validacaoForm, verificaValidacao } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { criarEmpresa, editarEmpresa, putListMeuProjetoTab, setListMeuProjetoTab } from '../../redux/meusProjetosActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'
import { modalAlertaInfo, modalAlertaClean } from '../../../../layout/redux/layoutActions'

export default ({ data }) => {
  const dispatch = useDispatch()
  const { idProjetos, idPasso } = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)
  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [120];
  const idarray1 = [121];

  const [erro, setErro] = useState({})
  const [form, setForm] = useState({})
  const [infomodal, setInfomodal] = useState({ selects: {} })

  useEffect(() => {
    let formInit = { nomeEmpresa: '', logo: '' }
    listRS.map(e => {
      formInit = { ...formInit, [e.id]: '' }
    })
    setForm({ ...form, ...formInit, ...data })
  }, [data])

  useEffect(() => {
    if (statusModal === 'criarEmpresaMeuProjeto') {
      setErro({});
    }
  }, [statusModal]);

  const listRS = [
    { id: 'urlFacebookEmpresa', label: 'Facebook', ph: `https://www.facebook.com/${nls.empresa}` },
    { id: 'urlInstagramEmpresa', label: 'Instagram', ph: `https://www.instagram.com/${nls.empresa}` },
    { id: 'urlTwitterEmpresa', label: 'Twitter', ph: `https://www.twitter.com/${nls.empresa}` },
    { id: 'urlYoutubeEmpresa', label: 'Youtube', ph: `https://www.youtube.com/@${nls.empresa}` },
    { id: 'urlTiktokEmpresa', label: 'Tiktok', ph: `https://www.tiktok.com/@${nls.empresa}` }
  ];
  

  const formRequired = {
    nomeEmpresa: ''
  }


  const handleChange = e => {
    setForm({ ...form, [e.name]: e.value })
  }

  const handleChangeFile = e => {
    setForm({ ...form, [e.name]: e.files })
  }

  const handlerCriar = (e) => {
    // Validação do formulário
    //const valid = validacaoForm({ formValues: form, formRequired: ['nomeEmpresa'] });
    //setErro(valid);
    //const verify = verificaValidacao(valid);

    //if (!verify.value) {
      // Se houver erro, não prossegue
    //  return;
    //}

    delete e.actions;
    delete e.checkboxCustom;

    // Fecha o modal de entrada de dados
    handleCloseModal();

    if (data?.empresaId) {
      // Caso seja para editar a empresa, executa diretamente
      dispatch(editarEmpresa({ ...e, projetoId: idProjetos, nlsPopup }));
    } else {
      // Caso seja para criar a empresa, exibe o modal de confirmação
      dispatch(
        modalAlertaInfo({
          ...nlsPopup[13],
          show: true,
          btnAction: () => {
            // Executa a criação após a confirmação
            dispatch(criarEmpresa({ ...e, projetoId: idProjetos, nlsPopup }));

            // Limpa o modal de confirmação
            dispatch(modalAlertaClean());
          },
        })
      );
    }
  };

  const handleCloseModal = () => {
    dispatch(modalClose())
  }
  
  const handleColar = e => {
    navigator.clipboard.readText().then((clipText) => {
      setForm({ ...form, [e]: clipText })
    });
  }

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.empresaId
            ? nls.configurar?.editEmpresaTitle
            : nls.configurar?.addEmpresaTitle}
          <PopoverCentralAjuda 
            nlsContexto={nlsContexto} 
            idarray={data?.empresaId ? idarray1 : idarray} 
          />
        </div>
      }
      size='large'
      open={statusModal === 'criarEmpresaMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.empresaId && (
          <Button
            color='default'
            onClick={() => handleCloseModal()}
          >{nls.configurar?.btnCancelar}</Button>
        )}
        <Button
          color='primary'
          onClick={() => {
          
            // Validação dos campos obrigatórios
          const valid = validacaoForm({ formValues: form, formRequired });
          setErro(valid);
          const verify = verificaValidacao(valid);

          if (!verify.value) {
            // Se houver erro, não prossegue
            return;
            
          }
            
            
            handlerCriar(form)} }
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-empresas-add'>
        <h5>{nls.configurar?.subTitleDetalhes}</h5>
        <div>
          <Input
            name='nomeEmpresa'
            label={nls.configurar?.formNome}
            value={form?.nomeEmpresa}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: nls.mensagem.obrigatorio,
              pattern: formRequired.nomeEmpresa
            }}
          />
        </div>
        <h5>{nls.configurar?.subTitleRedesSociais}</h5>
        <div className='colflex col2'>
          {listRS.map(e => (
            <Input
              key={e.id}
              name={e.id}
              label={e.label}
              value={form?.[e.id]}
              placeholder={e.ph}
              action={(e) => handleChange(e)}
              disabled={data?.empresaId && data?.[e.id]}
              right={<>
                <span className='btn-colar' onClick={() => !(data?.empresaId && data)?.[e.id] ? handleColar(e.id) : null}><IcoPaste /> {nls.configurar?.btnColar}</span>
              </>}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}
