import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoArchive, IcoHelpCircle, IcoImportar, IcoPaste, IcoUpload, Input, InputFile, Modal } from '../../../../components'
import { downloadArchive, history, numberAleatori } from '../../../../helpers'
import { importarEmpresa, setFormProjeto, setListMeuProjeto } from '../../redux/meusProjetosActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default ({data}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [119];



  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  const handleChange = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    console.log(e, 'handleChange');
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    dispatch(setListMeuProjeto({...e, id: numberAleatori(), status: true, licencaData: new Date().toISOString()}))
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  } 

  const validar = e => {
    readURL(e.target.files)
  }  
  
  const readURL = (input) => {
    if (input.length) {
      dispatch(importarEmpresa({projetoId: idProjetos, file: input[0], nlsPopup}))
      //dispatch(importarEmpresa({projetoId: idProjetos, file: input[0]}))
    }
  }

  const modelosTipo = {
    'pt-BR': {
      nome: 'Modelo-Importacao-Empresas',
      tipos: ['csv', 'xlsx']
    },
    'esp': {
      nome: 'Modelo-Importacion-Empresas',
      tipos: ['csv', 'xlsx']
    },
    'en-US': {
      nome: 'Companies-Import-Framework',
      tipos: ['csv', 'xlsx']
    },
  }
  
  const handleBaixarModelos = (e) => {
    const modelo = modelosTipo[sistema.idioma]
    modelo.tipos?.map(extension=> {
      const archive = require(`./modelos/${sistema.idioma}/${modelo.nome}.${extension}`)
      downloadArchive({archive, name: modelo.nome, extension})
    })
  }

  return (
    <Modal
    title={
      <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {nls.configurar?.importEmpresaTitle}
        <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
      </span>
    }

      size='medium'
      open={statusModal === 'importarEmpresaMeuProjeto' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls.criarProjeto?.btnFechar}
    >
      <div className='modal-meuProjeto-importar'>
        <div className='filearchive'>
            <input
              type={'file'}
              name={'importarempresas'}
              file={''}
              accept=".csv, .xlsx, .xls"
              onChange={(e) => validar(e)}
            />
          <div>
            {form.image?.filesUrl?<IcoArchive />:<IcoImportar />}
            <span>{form.image?.name?form.image?.name:''}</span>
            <Button
              color='primary'
              onClick={()=> handlerCriar(meuProjeto.projeto)}
            ><IcoUpload /> {nls.configurar?.btnImportarArquivo}</Button>
          </div>
        </div>
        <div>
          <Button
            type='link'
            color='info'
            onClick={()=> handleBaixarModelos(meuProjeto.projeto)}
          >{nls.configurar?.btnImportarModelo}</Button>
        </div>
        </div>
    </Modal>
  )
}



