import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../components'
import { putFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.comentariosState)
  const [ciclo1, setCiclo1] = useState({})
  const [ciclos1, setCiclos1] = useState([])

  const nls = require(`../nls/${sistema.idioma}.json`)

  
  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
 

  
  return (
   <div className='box-comentarios-search comentarios-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.cicloPorExtenso}</> }
         optionLabel='cicloPorExtenso'
         optionValue='anoMes'
      />
   </div>
  )
}
