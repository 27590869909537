import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Select } from '../../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/indicadoresActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, empresa, filters, grupo, listas: {typeSearch}, ciclos, categorias, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio, graficosDefault } = useSelector(state=> state.indicadoresredesState)

  const nls = require(`../nls/${sistema.idioma}.json`)
  
  //console.error(JSON.stringify(categorias, null, 2));
  //console.error(JSON.stringify(filtersProvisorio, null, 2));
 
  //console.error(JSON.stringify(filters, null, 2));
  
  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtersProvisorio, ...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])

  const handleSearching = (params) => {
    handleSearchingValid(params)
    // if (params.typeSelect.id === 'Ciclo') {
    // }
    // if (params.typeSelect.id === 'Data') {
    //   if (params.dataInicial && params.dataFinal) {
    //     handleSearchingValid(params)
    //   }
    // }
  }
  
  const handleSearchingValid = (params) => {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, grupo, params, typeSelect: params.typeSelect, filtersProvisorio, idioma: sistema.idiomaIdValue}))
  }
  
  const contentFilterType = (e) => {
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return;
    }
  }
  const handleCategoria = (e) => {
    //console.error(JSON.stringify(e, null, 2));
    dispatch(putFiltersProvisorio(e))
  }
  

  //console.error('FILTERPROVISORIOS.TYPESELECT.TEXT' + filtersProvisorio?.typeSelect?.text);

  const categoriasWithTodas = [
    {
      id: 0,
      descricao: nls.todascategoriasprojeto,
      ordem: 1
    },
    ...categorias // Spread the original categorias array
  ];

  return (
    <div className='box-indicadores-search box-content'>
      <div className='box-indicadores-search-box'>
        <div className='box-indicadores-search-type'>
          {
            typeSearch.map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === filtersProvisorio?.typeSelect?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{filtersProvisorio?.typeSelect?.text === 'Filtrar por Ciclo' ? nls.filtrarporciclo : nls.filtrarpordata}</p>
        </div>
        <div>
          {contentFilterType(filtersProvisorio?.typeSelect)}
        </div>

      </div>
      <div className='box-indicadores-search-action'>
          <div className='box-indicadores-search-select'>

            <Select
              name='categoria'
              options={categoriasWithTodas}
              
              //selected={filtersProvisorio?.categoria}
              selected={Object.keys(filtersProvisorio?.categoria).length === 0 ? categoriasWithTodas[0] : filtersProvisorio?.categoria}
              action={(e)=> handleCategoria(e)}
              // optionLabel='descricaoCategoria'
              // optionValue='categoriaId'
              optionLabel='descricao'
              optionValue='id'
              //textCustom={['Todas categorias', 'Selecionado', 'Selecionados', 'Marcar todos']}
              selectedItem={false}
            />
          </div>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching(filtersProvisorio)}
        >{nls.pesquisar}</Button>          
      </div>
    </div>
  )
}