import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoArrowBottom, IcoArrowTop, IcoClose, IcoMenu, IcoQuestionCircle, IcoUser2, Icobell, MaskData, dateCustom, useOutsideClick } from '../components'
import { apagarNotificacao, deleteNotificacao, deleteNotificacoes, listarNotificacoes, logOut, modalOpen, PortalClienteXD, setShowNotificacoes, setSidebarLeft, setSidebarRigth, userVisualizouNotificacoes, verificaConsumoConta, verificaCreditoConta } from './redux/layoutActions'
import imgLogo from '../layout/img/logo-yellow.png'
import { menuItensHeader, menuUserInfo } from './menuItens'
import { IF, history, idRdm } from '../helpers'
import ModalMeusDados from '../modules/meusDados/modalMeusDados'
import { setOpenCentralAjuda } from '../modules/centralAjuda/redux/centralAjudaActions'

import { PopoverCentralAjuda } from '../components/popovercentralajuda/popovercentralajuda';

export default () => {
  const dispatch = useDispatch()
  const {sistema, showNotificacoes, notificacoes, usuario, sidebarLeft, sidebarRight} = useSelector(state => state.layoutState)
  const { open } = useSelector(state=> state.centralAjudaState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const [menuHeader, setMenuHeader ] = useState([])

  const nlsMenu = menuItensHeader(usuario?.permissoes, nls)

  

  
  useEffect(()=> {
    setMenuHeader(nlsMenu)
    // dispatch(listarNotificacoes())
  }, [sistema.idioma, usuario?.permissoes])
  
  const handleSelect = (e) => {
    history.push(e.go)
    if (e.id === "CriarProjeto") {
      if (sistema?.creditoConta?.creditoProjetos) {
        dispatch(modalOpen('criarMeuProjeto'))
      } else{
        dispatch(modalOpen('obter-mais'))
      }
    }
  }
  
  
  return ( 
  <>
    <header id='box-header'>
      <button
        className={sidebarLeft ? 'btn-menu open' : 'btn-menu'}
        onClick={() => dispatch(setSidebarLeft(!sidebarLeft))}
      >
        <IcoMenu />
      </button>
      <div className='box-logo'>
        <img src={imgLogo} />
      </div>
      <div className='box-header-menu'>
        {
          menuHeader.map(e=> {
            return <button className='btn-header' onClick={() => handleSelect(e)} key={e.id}>
              {e.label}
          </button>
          })
        }
        <Button
          type='link'
          color='default'
          variant='normal circle'
          onClick={() => dispatch(setShowNotificacoes(!showNotificacoes))}
        ><Icobell />{sistema?.login?.temNotificacoesNaoLidas?<span className='flag'></span>:null}</Button>
        <Button
          type='link'
          color='default'
          variant='normal circle'
          onClick={() => dispatch(setOpenCentralAjuda(!open))}
        ><IcoQuestionCircle /></Button>
        <Button
          color='primary'
          variant='normal circle'
          onClick={() => dispatch(setSidebarRigth(!sidebarRight))}
        ><IcoUser2 /></Button>

      </div>
    </header>
    {sidebarRight?<BoxInfoUSer />:null}
    {/* modalMeusDadosCadastro */}
    {showNotificacoes? <BoxNotificacoes /> : null}
    <ModalMeusDados />
  </>
  )
}

const BoxInfoUSer = () => {
  const dispatch = useDispatch()
  const {sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const [verMais, setVerMais ] = useState(false)
  const nlsMenuUser = menuUserInfo(usuario.permissoes, nls)

  const nlsContexto = require(`../modules/centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [106];
  const idarray1 = [107];

  useEffect(()=> {
    dispatch([
      verificaConsumoConta()
    ])
  }, [])

  const handleMenu = e => {
    const closeId = {
      Dadoscadastro: true,
      Gerenciarusuários: true,
      MinhasAssinaturas: true,
      Suporte: true,
      
    }
    if (e.action) {
      dispatch(modalOpen(e.action))
    } 
    if(e.go) {
      history.push(e.go)
    }
    if (e.id === 'Sair') {
      dispatch(logOut())
    }
    if (e.id === 'Históricodefaturas') {
      dispatch(PortalClienteXD(window.location.href))
    }    
    if(closeId[e.id]){
      dispatch(setSidebarRigth(false))
    }
  }

  let informesUsuario = [
    {
      id: 'projetos',
      name: nls.informe.projetos,
      valor: sistema?.consumoConta?.projetosEmUsoConta,
      total: sistema?.consumoConta?.projetosPermitidosConta,
      color: '#0077DB'
    },
    {
      id: 'Empresas',
      name: nls.informe.empresas,
      valor: sistema?.consumoConta?.empresasEmUsoConta,
      total: sistema?.consumoConta?.empresasPermitidasConta,
      color: '#00A28F'
    },
    {
      id: 'Estabelecimentos',
      name: nls.informe.estabelecimentos,
      valor: sistema?.consumoConta?.estabelecimentosEmUsoConta,
      total: sistema?.consumoConta?.estabelecimentosPermitidosConta,
      color: '#FFB900'
    },
    {
      id: 'Produtos',
      name: nls.informe.produtos,
      valor: sistema?.consumoConta?.produtosEmUsoConta,
      total: sistema?.consumoConta?.produtosPermitidosConta,
      color: '#D7297D'
    },
    {
      id: 'Usuários',
      name: nls.informe.usuarios,
      valor: sistema?.consumoConta?.usuariosEmUsoConta,
      total: sistema?.consumoConta?.usuariosPermitidosConta,
      color: '#7629D7'
    },
  ]

  informesUsuario =  informesUsuario.filter(e=> e.total > 0)
  
  const ref = useRef(null);
  useOutsideClick(ref, (e) => {
    if (!e) {
      dispatch(setSidebarRigth(e))
    }
  });
  
  return <div className='box-modal-info-user' ref={ref}>
    <div className='box-modal-info-user-user'>
      <div>
        <p dangerouslySetInnerHTML={{__html: nls.informe.saudacao.replace('{{nomeUsuario}}', `<strong>${usuario?.nome}</strong>`)}}></p>
        <p style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <small>{usuario?.tipoConta}</small>
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </p>
      </div>
      {/* <div className='flag-success'>
        {nls.informe.plano}
      </div> */}
    </div>
    
    <div className='box-modal-info-user-info'>     
    {usuario?.permissoes?.VerSaldoEConsumoConta && 
 usuario?.avaliacoesPermitidasConta !== 0 ? (
  <div className='progress-bar'>
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <strong>{nls.infoUsuario.creditoFeedbacksMensais}</strong>
      <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray1} />
      <span>{usuario?.avaliacoesPermitidasConta}</span>
    </div>
  </div>
) : null}

      {
        usuario?.permissoes?.VerSaldoEConsumoConta && informesUsuario.map(e=> {
          const width = e.valor * 100 / e.total
          return <div key={e.id} className='progress-bar'>
            <div>
              <strong>{e.name}</strong>
              <span>{nls.porcentagem.replace('{{valor}}', e.valor).replace('{{total}}', e.total)}</span>
            </div>
            <div><div style={{backgroundColor: e.color, width: `${width}%`}}></div></div>
          </div>
        })
      }
      
    </div>
    <div className='box-modal-info-user-menu'>
       {nlsMenuUser.map(e=> {
        return <div key={`modalheader-${e.id}`} onClick={()=> handleMenu(e)}>
          {e.icon}
          <span>{e.label}</span>          
        </div>
       })}
    </div>
  </div>
}


const BoxNotificacoes = () => {
  const dispatch = useDispatch()
  const {sistema, notificacoes} = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  useEffect(()=> {
    dispatch(userVisualizouNotificacoes())
  }, [])

  const handleMenu = e => {
    if (e.action) {
      dispatch(modalOpen(e.action))
    } 
    if(e.go) {
      history.push(e.go)
    }
  }

  const ref = useRef(null);
  useOutsideClick(ref, (e) => {
    if (!e) {
      dispatch(setShowNotificacoes(e))
    }
  });
  
  return <div className='box-modal-notificacoes' ref={ref}>
    <div className='box-modal-notificacoes-title'>
      <h6>{nls?.modalNotificacoes.title}</h6>
      <div>
        <IF se={notificacoes?.length}>
          <Button
            type='link'
            onClick={()=> dispatch(apagarNotificacao(notificacoes))}
          >{nls?.modalNotificacoes.btnApagarNotificacoes}</Button>
        </IF>
      </div>
    </div>
    <div className='box-modal-notificacoes-content'>
      {notificacoes?.length ? notificacoes.map(e=> {
        return <div className='box-notificacao' key={idRdm()}>
          <div className='box-notificacao-title'>
            <h6>{e.tituloNotificacao}</h6>
            <div>
              <Button
                type='link'
                onClick={()=> dispatch(apagarNotificacao([e]))}
              ><IcoClose /></Button>
            </div>
          </div>
          <div className='box-notificacao-content'>
            <p>{e.textoNotificacao}</p>
            <p><small>{MaskData(dateCustom(e.dataEnvioNotificacao, ).fullData, '', sistema.idioma)}</small></p>
          </div>
        </div>
      }):<div className='box-notificacao-sem'>{nls.modalNotificacoes.sem}</div>}
    </div>
  </div>
}