import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal } from '../../../components'
import { modalClose } from '../../../layout/redux/layoutActions'
import { calculadoraContaEmpresarial, listaPeriodos, listarAtivos, setAtivos } from '../../minhasAssinaturas/redux/minhasAssinaturasActions'
import { checkoutContaXD } from '../redux/upgradeContaActions'

import { PopoverCentralAjuda } from '../../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { listas, ativos, avaliacoes } = useSelector(state=> state.upgradeContaState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [form, setForm] = useState({periodo: { "id": 4, "descricao": "ano", "percentualDesconto": "25 %" }})

  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [117];


  useEffect(()=> {
    if (statusModal === 'comprar-ativos') {
      dispatch(listarAtivos({consumoConta: sistema.consumoConta, moeda: usuario.moedaIso4217, tipo: 'CE', status: 'init', idioma: sistema.idiomaIdValue}))
      dispatch(listaPeriodos({idioma: sistema.idiomaIdValue}))
    }
  }, [statusModal, sistema.idioma, sistema.consumoConta])

  const handleChange = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    // dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: usuario.moedaIso4217}))
  }
  const handleChangeBlur = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: usuario.moedaIso4217}))
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }
  
  const handleSalvar = e => {
    const hasAtivos = ativos.filter(a=> a.quantidadeDesejada > 0)
    const params = {
      moeda: usuario.moedaIso4217,
      idPeriodo: e.periodo.id,
      ativos: [...ativos, {
        descricao: "Avaliações mensais",
        id: 6,
        quantidadeDesejada: avaliacoes.totalQueriesSemQueryPura30,
        valor: avaliacoes.totalQueriesSemQueryPura30
      }]

    }
    if (hasAtivos.length) {
      dispatch(checkoutContaXD(params, nlsPopup))
    }
  }
  
  const handleChangePeriodo = e => {
    setForm({...form, periodo: e})
  }
  
  const valorTotal = e => {
    const val = e?.find(f=> Number(f.id) === Number(form.periodo.id))
    return val?.valor
  }
  
  
  return (
    <Modal
      name='comprar-ativos'
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.adquirirContaEmpresarial}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </div>
      }
      open={statusModal === 'comprar-ativos' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar(form)}
        >{nls.adquirirContaEmpresarial}</Button>
      </>}

    >
      <div>
        <div className='periodos-lista'>
          <strong>{nls.selecionePeriodo}</strong>
          <div>
            {listas?.periodos?.map(e=> {
              console.log(e,);
              return <div key={e.id}>
                <Button
                  type='btn circle'
                  color={form?.periodo?.id === e.id ? 'primary' : 'default'}
                  size='small'
                  onClick={()=> handleChangePeriodo(e)}
                >{e.descricao}</Button>
              </div>
            })}
          </div>
        </div>
        <div className='ativos-lista-title'>
          <h5>{nls.adicionarAtivos}</h5>
        </div>
        <div className='ativos-lista'>
          {ativos.map(e=> {
            const min = e.id <= 2 ? e.id : 0
            return <div key={e.id} className='ativos-lista-item'>
              <div className='ativos-lista-item-info'>
                <strong>{e.descricao}</strong>
              </div>
              <div className='ativos-lista-item-input'>
                <Input 
                  type='number'
                  value={e.quantidadeDesejada}
                  action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                  actionBlur={a=> handleChangeBlur({...e, quantidadeDesejada: a.value})}
                  min={min}
                />
              </div>
            </div>
          })}
          <div className='ativos-lista-item'>
            <div className='ativos-lista-item-info'>
              <strong>{nls.avaliacoesMensais}</strong>
            </div>
            <div className='ativos-lista-item-input'>
              <div className='flag-primary'>
                {avaliacoes.totalQueriesSemQueryPura30}
              </div>
            </div>
          </div>
        </div>
        <div className='ativos-info'>
          <span>{nls.valorTotalAssinatura}: <strong>{valorTotal(avaliacoes.valores)}</strong> </span>
        </div>
      </div>
    </Modal>
  )
}
