import React, { useEffect, useState } from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/swotActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.swotState)

  const nls = require(`../nls/${sistema.idioma}.json`)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  return (
   <div className='box-swot-search swot-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         //labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         //optionLabel='mesAno'
         labelCustom={(e)=> <><IcoCicle /> {e.cicloPorExtenso}</> }
         optionLabel='cicloPorExtenso'
         optionValue='anoMes'
      />
   </div>
  )
}
