
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoAdd, IcoArrowRigth, IcoArrowRigthC, IcoCalendar, IcoCog, IcoHelpCircle, IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4, List, LoadingContent, MaskCpf, MaskData, Popover, dateCustom } from '../../components'


import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda';



import './meusProjetos.scss'
import { ativarInativaprojeto, listarMeusProjetos, projetoComentariosVistos, putListMeuProjeto, searchMovie } from './redux/meusProjetosActions'
import { useEffect } from 'react'
import Criar from './criar/criar-old'
import { modalOpen, setProjeto } from '../../layout/redux/layoutActions'
import { iconeprojeto, iconesList, typeStatusCustom } from './redux/meusProjetos'
import { IF, history } from '../../helpers'
import ObterMais from './obterMais'

import ModalAlerta from '../../layout/modalAlerta';
import { Modal } from '../../components'
import { modalAlertaInfo, modalClose, modalAlertaClean, informeNaoMostrar } from '../../layout/redux/layoutActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)

  //PARA CENTRAL DE AJUDA CONTEXTUAL
  const nomedir = `../../centralajuda/nlscontexto/${sistema.idioma}.json`;
  console.log("NOMEDIRETORIO", nomedir)
  const nlsContexto = require(`../centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [100];
  const idarray1 = [101];
  const idarray2 = [102];
  const idarray3 = [103];

  const { listMeusProjetos, paginate } = useSelector(state=> state.meusProjetosState)

  // Estado para o modal de confirmação
  //const [modalAberto, setModalAberto] = useState(false); // Define se o modal está aberto
  //const [projetoParaInativar, setProjetoParaInativar] = useState(null); // Define o projeto que será inativado

  //const titulomodal11 = nls.modalid.id11titulo
  //const textomodal11 = nls.modalid.id11texto
  //const cancelarmodal11 = nls.cancelar
  //const confirmarmodal11 = nls.confirmar
  const datarenovacao = sistema.dataAtualizacaoAssinatura

  
  const formatarDataRenovacao = (data, idioma) => {
    // Mapeia o idioma 'esp' para 'es-ES' para compatibilidade com Intl.DateTimeFormat
    if (!data) {
      //console.log("DATA NÃO É NO FORMATO VÁLIDO")
      return "";
    }
    //console.log("DATA é VÁLIDO1!!!")
    const locale = idioma === "esp" ? "es-ES" : idioma;
    //console.log("DATA é VÁLIDO2!!!")
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(new Date(data));
  };

    const datarenovacaoformatada = formatarDataRenovacao(datarenovacao, sistema.idioma);
    //const textomodal11Atualizado = textomodal11.replace("XXXXX", datarenovacaoformatada);
    //console.log("datarenovacao", datarenovacao)
    //console.log("sistema.idioma", sistema.idioma)
    //console.log("datarenovacaoformatada", datarenovacaoformatada)

  useEffect(()=> {
    dispatch(listarMeusProjetos())
  }, [sistema.idioma]) 

 

  const headerDefault = [
    {
      column: 'projetoCuston',
      text: nls.listarProjetos.tableProjetos,
      className: '',
    },
    {
      column: 'tipo',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.listarProjetos.tableTipo}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray1} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'segmentoDoProjeto',
      //text: nls.listarProjetos.tableSegmento,
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.listarProjetos.tableSegmento}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray2} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'statusCuston',
      //text: nls.listarProjetos.tableStatus,
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.listarProjetos.tableStatus}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray3} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'licencaDataCuston',
      text: nls.listarProjetos.tableLicenca,
      sort: '',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      sort: '',
      className: 'actions onlysvg',
    }
  ]

  const situacaoProjeto = [
    {id: 'A', name: nls.situacaoA},
    {id: 'I', name: nls.situacaoI},
    {id: 'AI', name: nls.situacaoAI},
  ]

  const handlePaginate = e => {
    console.warn(e, 'handlePaginate');
  }

  const handlePerPage = e => {
    console.warn(e, 'handlePerPage');
  }

  const handleConfigurar = e => {
    history.push(`/meusprojetos/${e.idProjeto}/configurar`)
  }

  const handleVizualizar = e => {
    
    dispatch(projetoComentariosVistos(e.idProjeto, e.nlsPopup));
    dispatch(setProjeto({...e, id: e.idProjeto}, `/meusprojetos/${e.idProjeto}/csat`))
  }

  
  const handleMudaStatus = e => {
    if (e.permissaoAlterarStatusProjeto) {
      if (!e.statusBolean) {       
          console.log("ENTROU 1"); //ATIVAR PROJETO INATIVADO
          dispatch(ativarInativaprojeto(e, nlsPopup))
          dispatch(modalAlertaClean()) //NÃO CONSEGUI SIMULAR AQUI                
      } else {
        console.log("ENTROU 3"); //INATIVAR PROJETO
        //dispatch(modalAlertaClean())
        dispatch(ativarInativaprojeto(e, nlsPopup))
        dispatch(modalAlertaClean()) //COLOQUEI PARA FECHAR O POPUP E DEU CERTO, NÃO CONSEGUI SIMULAR NAS SITAÇÕES ACIMA...
        
      }
      
    }
  }

//VERSÃO ANTIGA USAVA O MODAL OBTER-MAIS QUE CONTROLAVA NO FRONT O NÚMERO DE PROJETOS. AGORA VALIDAÇÃO NO BACK...
const handleMudaStatusOLD = e => {
  if (e.permissaoAlterarStatusProjeto) {
    if (!e.statusBolean) {
      if (sistema?.creditoConta?.creditoProjetos) {
        console.log("ENTROU 1"); //ATIVAR PROJETO INATIVADO
        dispatch(ativarInativaprojeto(e, nlsPopup))
        dispatch(modalAlertaClean()) //NÃO CONSEGUI SIMULAR AQUI
      } 
      else{
        console.log("ENTROU 2"); //ESSE É NO CASO DE TENTAR ATIVAR E NÃO TER CRÉDITO: Your account has no credits to create new projects. Get new credits by clicking here
        dispatch(modalOpen('obter-mais'))
        dispatch(modalAlertaClean()) //NÃO CONSEGUI SIMULAR AQUI
      }
    } else {
      console.log("ENTROU 3"); //INATIVAR PROJETO
      //dispatch(modalAlertaClean())
      dispatch(ativarInativaprojeto(e, nlsPopup))
      dispatch(modalAlertaClean()) //COLOQUEI PARA FECHAR O POPUP E DEU CERTO, NÃO CONSEGUI SIMULAR NAS SITAÇÕES ACIMA...
      
    }
    
  }
}

//const handleCancelar = e => {
//  dispatch(modalClose())
//}


  const handleCriar = e => {
    if (sistema?.creditoConta?.creditoProjetos) {
      console.log("ENTROU EM CRIAR PROJETO");
      //dispatch(modalOpen('AlterarContaEmpresarial'))
      dispatch(modalOpen('criarMeuProjeto'))
    } else{
      console.log("NÃO ENTROU EM CRIAR PROJETO POR CAUSA DE CRÉDITO");
      dispatch(modalOpen('obter-mais'))
    }
  }
  
  return (
    <div className='box-meus-projetos'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          {/*<h3>{nls.titleList} <Popover>{nls.mensagem.titlePopover}</Popover></h3>*/}
          <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {nls.titleList}
            <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
          </h3>
          <div>
            <IF se={usuario?.permissoes?.CriarNovoProjeto}>
              <Button
                color='primary circle'
                onClick={()=> handleCriar()}
              ><IcoAdd /> {nls.criar}</Button>
            </IF>
          </div>
        </div>
        <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={listMeusProjetos}
            listCustom={e=> {
              const item = e
              const statusInfo = typeStatusCustom(e.status)

              //const statusAction = a =>{
              //  if (a.situacao !== 'AI') {
              //    handleMudaStatus({...a, statusBolean: statusInfo.status})
              //  }
              //}

              //AQUI PARECE ESTAR FUNCIONANDO NA CHAMADA DO POPUP
              const statusAction = (a) => {
                if (a.situacao !== 'AI') {
                  const idpopup = a.situacao === 'A' ? 11 : 10;
                  // Dispara a ação modalAlertaInfo com o objeto necessário
                  dispatch(modalAlertaInfo({
                    ...nlsPopup[idpopup],  // Inclui o conteúdo de nlsPopup[5]
                    show: true,  // Garante que o modal será exibido
                    btnAction: () => handleMudaStatus({ ...a, statusBolean: statusInfo.status }), 
                    textos: {
                      'DataRenovacaoAssinatura': datarenovacaoformatada
                    }
                  }));
                  //handleCancelar()
                }
              };


              item.projetoCuston = <div className='info-projeto'>
                <div >{iconeprojeto(e?.icone)}</div>
                <div>
                  <label>{e.descricao}</label>
                  <p>{e.descricaoLonga}</p>
                </div>
              </div>

              item.statusCuston = e.permissaoAlterarStatusProjeto
              ? (e.situacao === 'AI'
                  ? <span className={`${statusInfo?.class} status-non-clickable`}>{nls[`situacao${e.situacao}`]}</span> // Apenas texto
                  : <span 
                      className={`${statusInfo?.class} status-clickable`} 
                      style={{cursor: 'pointer'}} 
                      onClick={() => statusAction(e)}
                    >
                      {nls[`situacao${e.situacao}`]}
                    </span>) // Botão clicável para outras situações
              : <span className={`${statusInfo?.class} status-non-clickable`}>{nls[`situacao${e.situacao}`]}</span>; // Apenas texto não editável





              // e.status?nls.ativo:nls.inativo
              item.licencaDataCuston = <span><IcoCalendar /> {MaskData(e.dataCriacao, '', sistema.idioma)}</span>
              item.actions = <div>
                <Button
                  type='link'
                  onClick={()=> handleConfigurar(e)}
                  disabled={!e.permissaoEditarProjeto || e.situacao === 'I'}
                ><IcoCog /></Button>
                <Button
                  type='link'
                  color='primary'
                  onClick={()=> handleVizualizar(e)}
                  disabled={e.situacao === 'I'}
                >                  
                  {e.temComentariosNaoVistos ? <IcoArrowRigthC /> : <IcoArrowRigth />}
                </Button>
              </div>

              return item
            }}
            // paginate={paginate}
            // actionPaginate={(e)=> handlePaginate(e)}
            // handlePerPage={(e)=> handlePerPage(e)}
            // nls={nls.paginacao}
          />
        </LoadingContent>
      </div>
      <Criar />
      <ObterMais type='conteudoProjetos'/>

    </div>
  )
}


