import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LoadingContent, Popover } from '../../components'

import './upgradeConta.scss'
import { useEffect } from 'react'
import { modalOpen, verificaConsumoConta } from '../../layout/redux/layoutActions'
import AdquirirAtivos from './content/adquirirAtivos'
import img1 from '../../layout/img/container/logos-1.png'
import img2 from '../../layout/img/container/logos-2.png'
import img3 from '../../layout/img/container/logos-3.png'

//import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  


  useEffect(()=> {
    dispatch([verificaConsumoConta()])
  }, [sistema.idioma])

  const handleConfigurar = (s) => {
    console.warn(s, 'handleConfigurar');
    if (s) {
      dispatch(modalOpen(s))
    }
  }

  const boxes = [
    {
      id: 0,
      text: nls.boxes['0'],
      img: img1,
    },
    {
      id: 1,
      text: nls.boxes['1'],
      img: img2,
    },
    {
      id: 2,
      text: nls.boxes['2'],
      img: img3,
    }
  ]
  
  return (
    <div className='box-upgrade-conta'>
        <h2>{nls.titleList}</h2>
        <p>{nls.subTitleList}</p>
        <LoadingContent
          show={false}
        >
          <div className='box-boxes'>
            {boxes.map(box=> {
              return <div key={`boxes-${box.id}`} className='box-boxes-box'>
                <p>{box.text}</p>
                <div className='box-boxes-img'>
                  {box.img? <img src={box.img} />:null}
                </div>
              </div>
            })}
          </div>
        </LoadingContent>
        <p>{nls.info}</p>
        <Button
          color='primary'
          onClick={e=> handleConfigurar('comprar-ativos')}
        >{nls.btnCalculadora}</Button>

      <AdquirirAtivos/>
    </div>
  )
}
