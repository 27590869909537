import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './projetos.scss';
import { useEffect } from 'react';
import { CheckoutProjetoProntoXD, getProjeto, listarProjetos, setProjetosFiltro } from './redux/projetosActions';
import { Button, LoadingContent, MaskValorMoedaex } from '../../components';
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos';
import { listarPaises, listarSegmentos, modalOpen } from '../../layout/redux/layoutActions';
import AdquirirModal from './adquirirModal';
import ProjetosFiltros from './projetosFiltros';
import { idiomaCustom, IF } from '../../helpers';
import { listaPeriodos } from '../minhasAssinaturas/redux/minhasAssinaturasActions';
import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda';


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, listas: { segmentos, paises } } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  const { projetos, listas, paginate } = useSelector(state=> state.projetosState)

  const nlsContexto = require(`../centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [105];
  


  useEffect(()=> {
    dispatch(listaPeriodos({idioma: usuario.idioma.id}))
    dispatch(listarSegmentos({idioma: idiomaCustom(sistema.idioma)}))
    dispatch(listarPaises({idioma: idiomaCustom(sistema.idioma)}))
  }, [])

  useEffect(()=> {
    if (segmentos.length && usuario?.segmentoInteresseId) {
      const segmentoSelected = segmentos.find(e=> e.id === usuario.segmentoInteresseId)
      dispatch(setProjetosFiltro({name: 'segmentos', value: segmentoSelected}))
    }
  }, [segmentos])

  useEffect(()=> {
    if ( sistema.pais) {
      dispatch(setProjetosFiltro({name: 'paises', value: sistema.pais}))
    }
  }, [paises])

  const handleCheckboxSelect = e => {
    const params = {
      id: e.Id,
      idPeriodo: 4,
      moeda: usuario?.moedaIso4217?usuario?.moedaIso4217:sistema?.moeda,
    }
    dispatch(CheckoutProjetoProntoXD(params, nlsPopup))
  }

  const handleVerMais = e =>{
      dispatch([
        getProjeto({...e, valor: e.valores[e.valores.length - 1]}),
        modalOpen('adquirirProjetoModal')
      ])
  }

  return (
    <div className='box-banco-projetos'>
      <div className='box-title'>
      <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {nls?.title}
        <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
      </h3>
        <ProjetosFiltros />
      </div>
      <div className='box-banco-projetos-container'>
        <div className='box-banco-projetos-content'>
          <LoadingContent
          show={false}
        >
          {projetos?.map(e=> {
            const valores = e.valores?.map(v=> {
                const periodo = listas.periodos?.find(f=> f.id === v.idPeriodo)
                return {...v, ...periodo}
            })
            e = {
              ...e, 
              valores
            }
            return <BoxProjeto nls={nls} idioma={sistema.idioma} moeda={usuario?.moedaIso4217?usuario?.moedaIso4217:sistema?.moeda} projeto={e} action={(d, s)=> s === 'saibamais' ? handleVerMais(e) : handleCheckboxSelect(e)} key={e.Id} />})}
        </LoadingContent>
        </div>
      </div>
      <AdquirirModal />
    </div>
  )
}

const BoxProjeto = ({
  nls,
  idioma,
  moeda,
  projeto,
  action=()=> null,
}) =>{
  const { usuario } = useSelector(state => state.layoutState)
  const privado = usuario.uniqueId ? true : false
  const valoesTipo = projeto?.valores?.length - 1
  
  const totalDividido = e => {
    console.log(e, 'totalDividido');
    const valor = e?.replace(/[^\d.,]/g, '').replace(',', '.');
    console.log(valor, 'totalDividido valor');
    
    const dividido = Number(valor) / 12
    return dividido
  }

  return <div key={projeto.Id} className='box-banco-projetos-projeto'>
    <div className='box-banco-projetos-projeto-topinfo'>
      <div className='box-banco-projetos-projeto-topinfo-info'>
        <div>{iconeprojeto(projeto.Icone)}</div>
        <div>
          <label>{projeto.Descricao}</label>
          <small>{projeto.Segmento}</small>
        </div>
      </div>
      <div>{}
        <strong>{projeto?.valorAnualCobradoMensal} / {nls.mes}</strong>
        <label><small>{nls.cobradoAnualmente}</small></label>
      </div>
    </div>
    <div className='box-banco-projetos-projeto-content'>
      <p className='box-banco-projetos-projeto-content-descricao'>{projeto.DescricaoLonga}</p>
      <div className='colflex col2'>
        <div>
          <label>{nls.empresas}</label>
          <span>{projeto.NomeEmpresasProjetoPronto}</span>
          {/* <span>{nls.empresasCadastros.replace('{{nEmpresas}}', projeto.nomeEmpresasProjetoPronto?.length)}</span> */}
        </div>
        <div>
          <label>{nls.pais}</label>
          <span>{projeto.Pais}</span>
        </div>
      </div>
    </div>
    <div className='box-banco-projetos-projeto-btns'>
      <Button
        color='primary'
        variant='outlined'
        onClick={()=> action(projeto, 'saibamais')}
      >{nls.saibaMais}</Button>
      {privado? 
        <Button
          color='primary'
          onClick={()=> usuario?.permissoes?.VerAdquirirProjetoPronto ? action(projeto, 'adquirir') : null}
          disabled={!usuario?.permissoes?.VerAdquirirProjetoPronto}
          title={!usuario?.permissoes?.VerAdquirirProjetoPronto ? nls.mensagem.apenasUserMaster : nls.btnAdquirirProjeto}
        >{nls.btnAdquirirProjeto}</Button>
        :
        <Button
          color='primary'
          onClick={()=> null}
          title={nls.btnCadastrar}
        >{nls.btnCadastrar}</Button>
      }
    </div>
  </div>
}
