import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './rankingnps.scss'
import Filter from './filter/filter'
import Grafico from './grafico'
import { carregarGrafico, filterHandle } from './redux/rankingnpsActions'
import { setInfoGrupo, setProjeto } from '../../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { grupos, grupo, filters, filterActive, sistema } = useSelector(state=> state.layoutState)
  const { projeto, grafico, totalAvaliacao, projetos } = useSelector(state=> state.rankingnpsState)

  const nls = require(`../rankingnps/nls/${sistema.idioma}.json`)
  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)


  const labelgraf = {
    atual: nls.labelgrafico.atual,
    anterior: nls.labelgrafico.anterior
  };


  useEffect(()=> {
    if (projeto.id&& !grafico.length) {
      if (filters.typeSelect) {
        dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filterActive, typeSelect: filters.typeSelect, idioma: sistema.idiomaIdValue, labelgraf }))
      } else {
        dispatch(carregarGrafico({projetoID: projeto.id, grupo, params: {TipoBusca: 'Ciclo', MesCliclo: '2023-01', Comparacao: true}, idioma: sistema.idiomaIdValue, labelgraf }))
      }
    }
  }, [projeto, grupo, sistema.idiomaIdValue])

  const handleFormFilter = (e) => {
    dispatch(setProjeto(e.value))
  }
  const handleGrupo = (e) => {
    dispatch(setInfoGrupo(e.value))
  }
  
  return (
    <div className='box-rankingnps'>
      <div className='box-rankingnps-topinfo'>
        <div>
          <div className='box-rankingnps-topinfo-select'>
            <Select 
              name='projeto'
              label={nls.projeto}
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormFilter(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-rankingnps-topinfo-select'>
            {/* <label>Cliente: </label> 
            <span> {projeto?.empresa}</span> */}
          </div>
          <div className='box-rankingnps-topinfo-select'>
            <Select 
              name='grupo'
              label={nls.grupo}
              options={grupos}
              selected={grupo}
              action={(e)=> handleGrupo(e)}
              selectedItem={false}
            />
          </div>
        </div>
        <div className='box-rankingnps-topinfo-avaliacao'>
            <label>{nls.numerodeavaliacoes}</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Grafico nlsContexto={nlsContexto} labelgraf={labelgraf} nls={nls} imprimir={totalAvaliacao > 0}/>
    </div>
  )
}
