import React from 'react';

export const IcoArrowRigthC = ({ ...props }) => {
  const style = props.style ? props.style : {}
  return (
    <svg
      className="icon-arrow-right-c"
      viewBox="0 0 12 12"
      {...props}
      style={{ ...style, transform: 'rotate(180deg)' }}
    >
      <path
        d="M6.83683 11.7488C6.99766 11.5879 7.08801 11.3698 7.08801 11.1423C7.08801 10.9148 6.99766 10.6966 6.83683 10.5357L2.90246 6.60136C2.7459 6.43825 2.65848 6.22091 2.65848 5.99482C2.65848 5.76873 2.7459 5.55139 2.90246 5.38828L6.83683 1.45391C6.9931 1.2921 7.07958 1.0754 7.07762 0.850454C7.07567 0.625513 6.98544 0.410339 6.82638 0.251276C6.66732 0.0922127 6.45214 0.00198708 6.2272 3.23934e-05C6.00226 -0.00192229 5.78555 0.0845505 5.62375 0.240825L1.68937 4.17434C1.45034 4.41334 1.26072 4.69708 1.13135 5.00937C1.00198 5.32166 0.935394 5.65637 0.935394 5.99439C0.935394 6.33241 1.00198 6.66713 1.13135 6.97942C1.26072 7.2917 1.45034 7.57545 1.68937 7.81445L5.62374 11.7488C5.78463 11.9096 6.0028 12 6.23029 12C6.45777 12 6.67595 11.9096 6.83683 11.7488Z"
        fill="currentColor"
      />
      {/* Ajustando o círculo vermelho */}
      <circle
        cx="11" // Posição horizontal ajustada para o canto direito
        cy="1"  // Posição vertical ajustada para o topo
        r="3"   // Raio dobrado
        fill="red" // Cor do círculo
      />
    </svg>
  )
}
