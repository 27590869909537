const initialState = {
  meusDados: {
    nome: '',
    sobrenome: '',
    email: '',
    celular: '',
    senha: '',
    idioma: '',
    perfil: { id: null, name: '' },
  },
  erro: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_MEUS_DADOS':
      return { ...state, meusDados: {...state.meusDados, ...payload} }
    case 'SET_MEUS_DADOS':
      return { ...state, meusDados: {...state.meusDados, [payload.name]: payload.value} }
    case 'CLEAN_MEUS_DADOS':
      return { ...state, meusDados: initialState.meusDados }
    case 'SET_MEUS_DADOS_ERROR':
      return { ...state, erro: payload }
    default:
      return state
  }
}

