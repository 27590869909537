import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoProjeto1, IcoProjeto2, IcoProjeto3, IcoProjeto4, Input, Modal, SelectDefault, TextArea, validacaoForm, verificaValidacao } from '../../../components'
import { cleanFormProjeto, criarProjeto, editarProjeto, setFormProjeto } from '../redux/meusProjetosActions'
import { iconesList } from '../redux/meusProjetos'
import { listarSegmentos, modalClose } from '../../../layout/redux/layoutActions'
import { PopoverCentralAjuda } from '../../../components/popovercentralajuda/popovercentralajuda';
import { modalAlertaInfo, modalAlertaClean} from '../../../layout/redux/layoutActions'


export default ({modal=true}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { projeto, statusModal, sistema, listas } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({
    nome: '',
    segmento: '',
    descricao: '',
  })

  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [104];

  
  const segmentoDoProjeto = "SEGMENTO XXX"
  const oquepode = "O QUE PODE XXX"
  const oquenaopode = "O QUE NÃO PODE XXX"

  useEffect(()=> {
    dispatch(listarSegmentos({idioma: idiomaCustom(sistema.idioma)}))
  }, [])

  useEffect(()=> {
    if (modal) {
      dispatch(cleanFormProjeto())
    }
  }, [modal])

  useEffect(()=> {
    const listSegment = listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]
    if (listSegment && listSegment?.length) {
      init(listSegment)
    }
  }, [meuProjeto.projeto, listas.segmentosIdiomas])

  const init = (listSegment) => {
    const segmentoSelect = listSegment.find(e=> e.id === meuProjeto.projeto?.segmentoId)
    let formInit = { 
      ...meuProjeto.projeto?.idProjeto ? {idProjeto: meuProjeto.projeto?.idProjeto}:{},
      nome: meuProjeto.projeto?.descricao,
      segmento: segmentoSelect,
      descricao: meuProjeto.projeto?.descricaoLonga,
      icone: meuProjeto.projeto?.icone
    }
    setForm({ ...form, ...formInit})
  }

  const formRequired = {
    nome: '',
    segmento: '',
    descricao: ''
  }

  const handleChange = e => {
    setForm({...form, [e.name]: e.value})
    // dispatch(setFormProjeto({name: e.name, value: e.value, tipo: 'projeto'}))
  }

  const idiomaCustom = e => {
    e = e === 'en-US'?'en':e
    return e.toLowerCase()
  }

  const handlerConfigurar = e => {
    
  } 

  //USADO QUANDO NÃO TINHA POPUP DE CONFIRMAÇÃO, AÍ PRECISAVA FAZER A VERIFICAÇÃO.
  
  const handlerCriarOLD = (formValues, config) => {
    
    const valid = validacaoForm({formValues, formRequired})
    setErro(valid)
    const verify = verificaValidacao(valid)
    if (verify.value) {
      if (formValues.idProjeto) {
        dispatch(editarProjeto(formValues, nlsPopup))
      } else {
        dispatch(criarProjeto(formValues, config, nlsPopup))
        dispatch(modalAlertaClean())
      }
    }
  }

  //COM POPUP DE CONFIRMAÇÃO, A VERIFICAÇÃO DE CAMPOS PREENCHIDOS ACONTECE NO JSX
  const handlerCriar = (formValues, config) => {
     
      if (formValues.idProjeto) {
        dispatch(editarProjeto(formValues, nlsPopup))
      } else {
        dispatch(criarProjeto(formValues, config, nlsPopup))
        dispatch(modalAlertaClean())
      }  
  }

  const handlerDescartar = e => {
    const listSegment = listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]
    if (listSegment && listSegment?.length) {
      init(listSegment)
    }
  } 


  const contentPage = () => <>
  <div className='modal-criarMeuProjeto'>
      <div className='colflex col2'>
        <Input
          name='nome'
          label={nls.criarProjeto?.nomeProjeto}
          value={form?.nome}
          placeholder={nls.criarProjeto?.nomeProjetoPlaceholder}
          action={(e) => handleChange(e)}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.nome
          }}
        />
        <SelectDefault
          name='segmento'
          //label={nls.criarProjeto?.segmento}
          label={
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {nls.criarProjeto?.segmento}
              <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
            </div>
          }
          options={listas.segmentosIdiomas[idiomaCustom(sistema.idioma)]}
          selected={form?.segmento?form?.segmento:''}
          action={(e) => handleChange(e)}
          textCustom={nls.select}
          optionLabel='nome'
          optionValue='id'
          disabled={idProjetos && idProjetos !== 'novo'?true:false}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.segmento
          }}
        />
      </div>
      <div className="form-box">
        <label className="label-input">
          <span className="input-label-span-text">{nls.criarProjeto?.iconeProjeto}</span>
        </label>
        <div className="form-icones">
          {iconesList.map(e=> {
            return <div key={`icone-${e.id}`} onClick={()=> handleChange({name: 'icone', value: e.id})} className={form.icone === e.id? 'active': ''}>
              <div className='iconeprojeto' style={{backgroundColor: e.color}}>{e.icon}</div>
            </div>
          })}
        </div>
      </div>
      <TextArea
        name='descricao'
        label={nls.criarProjeto?.descricao}
        value={form?.descricao}
        placeholder={nls.criarProjeto?.descricaoPlaceholder}
        action={(e) => handleChange(e)}
        required={{
          erro,
          message: nls.mensagem.obrigatorio,
          pattern: formRequired.descricao
        }}
      />
    </div>
  </>

  if (modal) {
    return ( <Modal
      title={nls.criarProjeto?.titleCriar}
      size='medium'
      open={statusModal === 'criarMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        
        <Button
          color='primary'
          onClick={() => {

          // Validação dos campos obrigatórios
          const valid = validacaoForm({ formValues: form, formRequired });
          setErro(valid);
          const verify = verificaValidacao(valid);

          if (!verify.value) {
            // Se houver erro, não prossegue
            console.log("ERRO EM CRIAR PROJETO")
            return;
          }

            // Fecha o modal do formulário
            dispatch(modalClose());

            // Depois de fechar o primeiro modal, abrimos o modal de confirmação
            dispatch(
              modalAlertaInfo({
                ...nlsPopup[15],
                show: true,
                btnAction: () => handlerCriar(form,true),
                textos: {
                  segmentoDoProjeto: form.segmento?.nome,
                  oquepode: form.segmento?.cadastroProduto === 1 
                    ? nls.produtopode
                    : form.segmento?.cadastroLoja === 1 
                      ? nls.estabelecimentopode 
                      : form.segmento?.id || oquepode,
                  oquenaopode: form.segmento?.cadastroProduto === 1
                    ? nls.estabelecimentopode
                    : form.segmento?.cadastroLoja === 1
                      ? nls.produtopode
                      : oquenaopode,
                },
              })
            );
          }}
        >
          {nls.criarProjeto?.btnCriar}
        </Button>
      </>}
    >
      {contentPage()}
    </Modal>)
  } else {
    return <>
    {contentPage()}
    <div className='box-btns'>
        <Button
          color='default'
          onClick={()=> handlerDescartar(form)}
        >{nls.configurar.btnDescartar}</Button>
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar.btnSalvar}</Button>
      </div>
    </>
  }
}
