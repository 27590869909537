export const colors = ['#0077DB', '#00a28f']
export const graficoOptions = {
  colors: colors,
  BarChart: {
    width: 500,
    height: 300,
    margin: {
      top: 40,
      right: 40,
      left: 20,
      bottom: 20,
    }
  },
  CartesianGrid: {
    stroke: '#e6e6e6',
    vertical: false
  },
  Brush:{
    dataKey: 'name',
    height: 30,
    stroke: colors[0],
    startIndex: 0,
    endIndex: 4,
  },
  XAxis: {
    dataKey: 'name',
    fontSize: '12px',
    stroke: '#6c7293',
    axisLine: false,
    tickLine: false,
  },
  YAxis: {
    unit: "",
    fontSize: '12px',
    stroke: "#6c7293", 
    axisLine: false,
    tickLine: false,
  },
  Legend: {
    align: 'right'
  },
  Bar: {
    radius: [6, 6, 6, 6],
    label: {
      fill: '#6c7293',
      unit: '',
      fontSize: '11px',
    }
  }
}

export const CustomLabel = ({x, y, width, offset, name, value}) => (
  <text name={name} x={x} y={value < 0 ? y + 16 : y} dy={-4} dx={(width - offset) / 2} width={width} fontSize={graficoOptions.Bar.label.fontSize} fill={graficoOptions.Bar.label.fill}  textAnchor="middle">{value}{value?graficoOptions.Bar.label.unit:''}</text>
)

export const CustomTooltipOLD = ({ active, payload, label, labels }) => (active && payload && payload.length ? (
  <div className="custom-tooltip">
    <p className="label">{`${label}`}</p>
    {payload.map(({name, value, color}, i)=> {
      const labelItem = labels.filter(f=> f.type === name)
      return (
      <p className="desc"key={`tooltipcustom-${name}-${i}`}>
        <span style={{backgroundColor: color}}></span> 
        {`${labelItem.length && labelItem[0].name} : ${value}${graficoOptions.Bar.label.unit}`}
      </p>
    )})}
  </div>
):  null)

export const CustomTooltip = ({ active, payload, label, labels, msgNA, labelgraf }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        {payload.map((infopay, i) => {
          const { name, value, payload: payData } = infopay;

          // Normaliza o nome baseado no labelgraf
          const normalizedName =
            name === "Atual" ? labelgraf.atual : name === "Anterior" ? labelgraf.anterior : name;

          // Recupera o label correspondente no array labels
          const labelItem = labels.filter(f => f.type === `${normalizedName}${payData.key}`);

          // Verifica se o dado está indisponível (info === -1)
          const displayValue = payData.info === -1 ? msgNA : value;

          return (
            <p className="desc" key={`tooltipcustom-${name}-${i}`}>
              <span style={{ backgroundColor: infopay.payload[`color${name}`] }}></span>
              {`${labelItem.length ? labelItem[0].name : "Rótulo não encontrado"} : ${displayValue}${
                displayValue !== msgNA ? "%" : ""
              }`}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};






export const RenderLegend = ({ payload, labels }) => (
  <div className={`custom-legend legend-${graficoOptions.Legend.align}`}>
    {payload.map((entry, i) => {
      const labelItem = labels.filter(f=> f.type === entry.value)
      return (
      <div key={`item-${i}`}>
        <span style={{backgroundColor: entry.color}}></span> 
        {labelItem.length && labelItem[0].name}
      </div>
    )})}
  </div>
)