import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom";
import { Button, IcoClose, IcoLogo, Menu } from '../components'

import { menuItens } from './menuItens'
import { setSidebarLeft, setTitlePage } from './redux/layoutActions'
import imgLogo from '../layout/img/logo-custom.png'



export default ({}) => {
  const dispatch = useDispatch()
  const {idProjetos} = useParams()
  const {sidebarLeft, projeto, sistema} = useSelector(state => state.layoutState)

  const nls = require(`./nls/${sistema.idioma}.json`)



  const [menuDash, setMenuDash ] = useState()
  
  useEffect(()=> {
    if (idProjetos && projeto?.id) {
      const nlsMenu = menuItens({id: idProjetos, projeto, nls})
      setMenuDash(nlsMenu)
    }
  }, [idProjetos, projeto, sistema.idioma])

  const handleSelect = ({sub}) => {
    if (!sub) {
      dispatch([
        setSidebarLeft(false)
      ])
    }
  }
  
  return (
    <div id='box-sidebar-left' className={sidebarLeft ? 'open-sidebar' : ''}>
      {/* <div className='box-logo'>
        <div>
          <img src={imgLogo} />
        </div> 
        <div className='box-logo-close'>
          <Button
            type='link'
            onClick={() => dispatch(setSidebarLeft(false))}
          >
            <IcoClose />
          </Button>
        </div>
      </div> */}
      <div className='box-sidebar-left-scrool'>
        <Menu data={menuDash} action={(e)=> handleSelect(e)} />
      </div>
    </div>
  )
}
