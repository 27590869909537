import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from '../components'
import { IF } from '../helpers'
import { popupEmailSuccess, popupError, popupSuccess } from './img/image'
import { modalAlertaClean, modalClose } from './redux/layoutActions'

export default ({}) => {
  const dispatch = useDispatch()
  const { alerta, sistema, statusModal } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const textoCustom = (e, l) => {
    let texto = e
    if (l) {
      Object.keys(l).map(c=> {
        texto = texto.replace(`{{${c}}}`, l[c])
      })
    }
    return texto
  }

  const imgCustom = e => {
    switch (e.type) {
      case 'success':
        return popupSuccess();
      case 'emailsuccess':
        return popupEmailSuccess();
      case 'error':
        return popupError();
      default:
        return;
    }
  }
  
  const contentAlerta = (e) => {
    console.log(e, 'contentAlerta');
    const htmlContent = textoCustom(e.texto, e.textos);
    return <>
      {e.type && <div className='modal-modalAlerta-img'>{imgCustom(e)}</div> }
      <strong>{e.titulo}</strong>
      <p dangerouslySetInnerHTML={{ __html: htmlContent }}></p>
      
      <IF se={e.btn && e.btnAction}>
        <div className='modalAlerta-btns'>
          <Button
            type='btn block'
            color='primary'
            onClick={e.btnAction}
          >{e.btn}</Button>
        </div>
      </IF>
    </>
  }
  return (
    <Modal
      size='medium'
      open={alerta.show}
      title={<div></div>}
      close={()=> dispatch(modalAlertaClean())}
      closeText={nls.fechar}
    >
      <div className='modal-modalAlerta'>
        {contentAlerta(alerta)}
      </div>
    </Modal>
  )
}
