import React from 'react';

const Plano7diasContent = () => {
  return (
    <div className="plano7dias-content">
   <h2 className="plano7dias-content-title"><strong>Resumo das Experiências de Compra dos Clientes</strong></h2>
   <p className="plano7dias-content-text">Os comentários dos clientes do Shopping Paulista destacam uma experiência geralmente positiva, com elogios ao bom atendimento, variedade de lojas e à praça de alimentação. No entanto, há críticas relacionadas ao atendimento, especialmente em relação à falta de experiência de alguns funcionários, o que pode impactar a satisfação do cliente. Além disso, alguns clientes mencionaram problemas com a lotação do shopping, especialmente na praça de alimentação e no estacionamento. Por outro lado, os comentários sobre o Shopping Eldorado revelam insatisfações com a falta de vagas de estacionamento, a escassez de banheiros femininos e a necessidade de modernização das instalações. Apesar disso, o Eldorado é elogiado pela variedade de lojas e opções de lazer.</p>
   
   <h2 className="plano7dias-content-title"><strong>Plano de Ação</strong></h2>
   
   <table className="plano7dias-content-table">
     <thead>
       <tr>
         <th><strong>Ação</strong></th>
         <th><strong>Atividade</strong></th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <td>1</td>
         <td>Implementar um programa de treinamento intensivo para os novos funcionários, focando em atendimento ao cliente e habilidades de comunicação, para garantir que todos os colaboradores estejam preparados para oferecer um serviço de qualidade.</td>
       </tr>
       <tr>
         <td>2</td>
         <td>Destacar a variedade e qualidade da praça de alimentação em campanhas de marketing, enfatizando que o Shopping Paulista oferece uma experiência gastronômica diversificada, contrastando com as críticas ao estacionamento e à lotação do Shopping Eldorado.</td>
       </tr>
       <tr>
         <td>3</td>
         <td>Realizar uma campanha de feedback com os clientes que visitam o shopping, incentivando-os a compartilhar suas experiências e sugestões, para identificar áreas de melhoria e reforçar o compromisso com a satisfação do cliente.</td>
       </tr>
       <tr>
         <td>4</td>
         <td>Promover eventos especiais nos finais de semana para atrair mais visitantes, como feiras de artesanato ou apresentações musicais, aproveitando a localização e a estrutura do shopping para criar um ambiente mais atrativo.</td>
       </tr>
       <tr>
         <td>5</td>
         <td>Reforçar a comunicação sobre a facilidade de acesso ao shopping, destacando a proximidade com a Avenida Paulista e a 23 de Maio, para atrair clientes que buscam conveniência em suas visitas.</td>
       </tr>
       <tr>
         <td>6</td>
         <td>Explorar a fragilidade do Shopping Eldorado em relação ao estacionamento, promovendo o Shopping Paulista como uma opção com melhor acesso e mais vagas disponíveis, especialmente em horários de pico.</td>
       </tr>
     </tbody>
   </table>
 </div>
  );
}

export default Plano7diasContent;
