import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Loading } from '../../../components';
import { List } from './list/list';
import { filterHandle, listarInfoGrupo } from './redux/infoGrupoActions';
import { capitalize } from '../../../helpers';
import { setFiltered } from '../../../layout/redux/layoutActions';

export default ({ imprimir }) => {
  const dispatch = useDispatch()
  const { filters, grupo, sistema } = useSelector(state=> state.layoutState)
  const { 
    loadList, 
    projeto, 
    infoGrupo, 
    paginate, 
    filtersProvisorio 
  } = useSelector(state=> state.infoGrupoState)
  
  const nls = require(`./nls/${sistema.idioma}.json`)
  console.log("Valor registro dentro da tabela:", nls.registro)

  const [sortColumn, setSortColumn] = useState({})

  const headerDefault = [
    {
      column: 'cidade',
      text: nls.cidade,
      className: '',
    },
    {
      column: 'estado',
      text: nls.estado,
      // sort: 'sort',
      className: '',
    },
    {
      column: 'loja',
      text: nls.estabelecimento,
      // sort: 'sort',
      className: '',
    },
    {
      column: 'empresa',
      text: nls.empresa,
      // sort: 'sort',
      className: '',
    }
  ]

  const handlePaginate = (e) => {
    dispatch(filterHandle({
      projetoID: projeto.id, 
      params: filters, 
      grupo,
      typeSelect: filters.typeSelect,
      filtersProvisorio,
      paginate: {...paginate, pageNumber: Number(e)}
    }))
  }

  const handlePerPage = (e) => {
    dispatch(filterHandle({
      projetoID: projeto.id, 
      params: filters, 
      grupo,
      typeSelect: filters.typeSelect,
      filtersProvisorio,
      paginate: {...paginate, perPage: Number(e.value.id)}
    }))
  }
  const handleSort = (e) => {
    const infoOrderby = {
      up: 'Desc',
      down: 'ASC'
    }
    let filteredCustom = {...filters}
    setSortColumn(e)
    if (e.type === 'normal') {
      delete filteredCustom.Coluna
      delete filteredCustom.Ordem
    } else {
      filteredCustom= {
        Coluna: capitalize(e.column),
        Ordem: infoOrderby[e.type]
      }
    }
    dispatch([
      filterHandle({
        projetoID: projeto.id, 
        params: filteredCustom,
        grupo,
        typeSelect: filters.typeSelect,
        filtersProvisorio,
        paginate: {...paginate}
      })
    ])
  }


  if (!imprimir) {
    return (
      <div className="box-infoGrupo-tabela box-content box-infoGrupo-tabela-mensagem">
        <p>{nls.semdadosparaexibir}</p>
      </div>
    );
  }


  return (
    <>
      <div className='box-infoGrupo-tabela box-content'>
        <div className="box-tabela-content">
          {
            loadList?
            <Loading type='element' title='' height='300px' />
            :
            <List 
              header={headerDefault}
              data={infoGrupo}
              paginate={paginate}
              sort={sortColumn}
              actionPaginate={(e)=> handlePaginate(e)}
              handlePerPage={(e)=> handlePerPage(e)}
              handleSort={(e)=> handleSort(e)}
              nlsgeral={nls}
            />
          }
        </div>
      </div>
    </>
  )
}
