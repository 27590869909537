import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, RadioButton, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/infoGrupoActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.infoGrupoState)

  const nls = require(`../nls/${sistema.idioma}.json`)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }


 const desativar = nls.desativar
 const ativar = nls.ativar


  const compararCiclosList = [
   {
      id: 'desativar',
      name: desativar,
   },
   {
      id: 'ativar',
      name: ativar,
   }
  ]
  
  return (
   <div className='box-infoGrupo-search infoGrupo-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         //labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         //optionLabel='mesAno'
         labelCustom={(e)=> <><IcoCicle /> {e.cicloPorExtenso}</> }
         optionLabel='cicloPorExtenso'
         optionValue='anoMes'
      />
      {/* <RadioButton
         name='compararCiclos'
         label='Comparar ciclos'
         options={compararCiclosList}
         checked={compararCiclos}
         action={(e)=> handleFormFilter(e)}
      /> */}
   </div>
  )
}
