const initialState = {
    sugestoes: [], // Estado inicial vazio
  };
  
  export default function sugestoesEnderecosReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_ENDERECOS_SUGESTOES':
        return { ...state, sugestoes: action.payload }; // Atualiza com as sugestões
      case 'CLEAR_ENDERECOS_SUGESTOES':
        return { ...state, sugestoes: [] }; // Limpa as sugestões
       
      default:
        return state; // Retorna o estado original para ações desconhecidas
    }
  }
  