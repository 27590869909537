import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, Modal, validacaoForm, verificaValidacao } from '../../components'
import AddContent from './addContent'
import { cleanUsuario, criarUsuario, editarUsuario } from './redux/usuariosActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { usuario } = useSelector(state => state.usuariosState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)

  const [erro, setErro] = useState({}); // Adicionado o estado para controlar erros
  const [permissionError, setPermissionError] = useState(false);

  useEffect(()=> {

  }, [])

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom

     // Validação de campos obrigatórios
  const formRequired = {
    email: '',
    perfil: '',
  };

  const valid = validacaoForm({ formValues: e, formRequired });
  setErro(valid); // Atualiza o estado de erro para renderizar mensagens e bordas

  const verify = verificaValidacao(valid);

  // Interrompe a execução se houver erros
  if (!verify.value) {
    console.log("Erro na validação dos campos:", valid);
    return;
  }

    const hasPermission = e.permissoes.filter(f=> f.podeVisualizar || f.podeEditar)
    
    // TIREI A RESTRIÇÃO DE CHECAR SE USUÁRIO PADRÃO TEM 1 PROJETO DE VISUALIZAÇÃO, PORQUE TODOS PRECISAM TER
    //if (hasPermission.length === 0 && e.perfil?.id === 3) {
    if (hasPermission.length === 0) {
      setPermissionError(true);
      return; // Não prossegue se a condição não for atendida
    }

    // Se não houver problemas, reseta o erro de permissão
    setPermissionError(false);
    

    if (hasPermission.length) {
      if (usuario?.id) {
        dispatch(editarUsuario(e, nlsPopup))    
      } else {
        dispatch(criarUsuario(e, nlsPopup))
        //console.log("PERMISSÕES", hasPermission)
        //console.log("PERMISSÕES PERFIL", e.perfil)
      }
      handleCloseModal()
    }
  }  

  const handleCloseModal = e => {
    dispatch([
      cleanUsuario(),
      modalClose()
    ]) 
    setErro(false);
    setPermissionError(false);
  }  

  return (
    <Modal
      title={usuario?.id?nls?.editTitle:nls?.addTitle}
      size='medium'
      open={statusModal === 'criarProdutoMeuProjeto' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls?.btnFechar}
      actions={<>
        {usuario?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(usuario)}
        >{nls?.btnFinalizarSalvar}</Button>
      </>}
    >
     <AddContent 
        erro={erro}
        permissionError={permissionError}        
    />
    </Modal>
  )
}

