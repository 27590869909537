import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/indicadoresActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema  } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.indicadoresState)

  const nls = require(`../nls/${sistema.idioma}.json`)

  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  
  console.log("CICLOS", ciclos)
  console.log("CICLO", ciclo)

  return (
   <div className='box-indicadores-search indicadores-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.cicloPorExtenso}</> }
         //labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         //optionLabel='mesAno'
         optionLabel='cicloPorExtenso'
         optionValue='anoMes'
      />
   </div>
  )
}
