import axios from 'axios';
import { gerarLista } from './usuarios';
import { numberAleatori, paramsApi } from '../../../helpers';
import { loaded, loading, modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions';
import api from '../../../helpers/api';
import { AddAlert } from "../../../components"


export const getUsuarios = payload => ({
  type: 'GET_USUARIOS',
  payload
})

export const setUsuariosPaginate = payload => ({
  type: 'SET_USUARIOS_PAGINATE',
  payload
})

export const cleanUsuariosPaginate = payload => ({
  type: 'CLEAN_USUARIOS_PAGINATE',
  payload
})

export const getUsuario = payload => ({
  type: 'GET_USUARIO',
  payload
})

export const postUsuario = payload => ({
  type: 'POST_USUARIO',
  payload
})

export const putUsuario = payload => ({
  type: 'PUT_USUARIO',
  payload
})

export const deleteUsuario = payload => ({
  type: 'DELETE_USUARIO',
  payload
})

export const setUsuario = payload => ({
  type: 'SET_USUARIO',
  payload
})

export const cleanUsuario = payload => ({
  type: 'CLEAN_USUARIO',
  payload
})

export const loadUsuarios = payload => ({
  type: 'LOAD_USUARIOS',
  payload
})

export const errorUsuarios = payload => ({
  type: 'ERROR_USUARIOS',
  payload
})

export const listarUsuarios = e => {
  const params = paramsApi(e)
  return dispatch => {
    dispatch(loading())
    api.get(`/Usuario/ListaUsuariosConta${params}`)
    .then(({data}) => {
      dispatch([
        getUsuarios(data),
        loaded()
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
        dispatch(loaded())
    });
  }
}

export const criarUsuario = (e, nlsPopup) => {
  let paramsAcessos = []
  e?.permissoes.filter(p=> p.podeVisualizar || p.podeEditar).map(p=> {
    if (p.podeVisualizar) {
      paramsAcessos = [
        ...paramsAcessos, 
        { 
          tipoConfig: "Projeto",
          valorConfig: String(p.id)
        }
      ]
    }
    if (p.podeEditar) {
      paramsAcessos = [
        ...paramsAcessos, 
        { 
          tipoConfig: "EditaProjeto",
          valorConfig: String(p.id)
        }
      ]
    }

  })
  
  const paramsCuston = {
    email: e.email,
    perfilUsuarioId: e.perfil.id,
    acessos: paramsAcessos,
  }
  return dispatch => {
    dispatch(loading())
    api.put(`/Usuario/ConvidaUsuario`, paramsCuston)
    .then(result => {
      dispatch([
        // postUsuario(result.data),
        loaded(),
        AddAlert('success', nlsPopup.mensagens.convidadocomsucesso)
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
        dispatch(loaded())
        dispatch(modalClose())
        //ABRE O POPUP COM MENSAGEM DE ERRO
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[113], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const editarUsuario = (e, nlsPopup) => {

  const params = {
    userId: String(e.uniqueId),
    idPerfil: e.perfil.id,
    permissoes: e.permissoes.map(p=> ({
      projetoId: p.id,
      podeVisualizar: p.podeVisualizar?p.podeVisualizar:false,
      podeEditar: p.podeEditar?p.podeEditar:false
    }))
  }
  return dispatch => {
    dispatch(loading())
    api.put(`/Usuario/EditaUsuario`, params)
    .then(result => {
      dispatch([
        putUsuario(result.data),
        loaded(),
        AddAlert('success', nlsPopup.mensagens.editadocomsucesso)
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
        dispatch(loaded())
    });
  }
}

export const removerUsuario = e => {
  const params = paramsApi(e)
  return dispatch => {
    dispatch(loading())
    api.put(`/Usuario/DeletaUsuario${params}`)
    .then(result => {
      dispatch([
        deleteUsuario(e),
        loaded()
      ])
    })
    .catch(err => {
        console.error(err, 'salvarMeusDados err');
        dispatch(loaded())
    });
  }
}







    // axios.get(``)
    // .then(result => {
    //     console.warn(result, 'listarUsuarios');
    // })
    // .catch(err => {
    //     console.error(err, 'listarUsuarios');
    // });

