import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoHelpCircle, IcoPaste, IcoUpload, Input, InputFile, Modal, Popover, Select, validacaoForm, verificaValidacao } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { criarProduto, editarProduto, putListMeuProjetoTab, setListMeuProjetoTab, setMeusProjetosLoad } from '../../redux/meusProjetosActions'
import { GruposProdutos } from './grupos'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default ({data, onConfirmCreate}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { load, meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)


  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { 
      nomeProduto: '', 
      grupoProduto: '', 
      urlAmazonProduto: '',
      nomeEmpresa: '',
      subSegmento: ''
    }
    
    let formData = {}
    if (data?.idProduto) {
      formData = { 
        ...data, 
        grupoProduto: meuProjeto.gruposProdutos?.find(e=> e.idGrupoProduto === data?.grupoProdutoId), 
        subSegmento: meuProjeto.subsegmentos?.find(e=> e.idSubSegmento === data?.subsegmentoId), 
        nomeEmpresa: meuProjeto.empresas?.find(e=> e.empresaId === data?.empresaId), 
      }
    }

    setForm({...form, ...formInit, ...formData})
  }, [data])

  const formRequired = { 
    nomeProduto: '', 
    grupoProduto: 'select', 
    nomeEmpresa: 'select',
    subSegmento: 'select',
    urlAmazonProduto: '',
  }

  const handleChange = e => {
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = formValues => {
    delete formValues.actions
    delete formValues.checkboxCustom
    
    const valid = validacaoForm({formValues, formRequired})
    
    setErro(valid)
    const verify = verificaValidacao(valid)
    if (verify.value) {
      handleCloseModal(); // Fecha o modal de criação

      // Chama a função de confirmação com os dados preenchidos
      if (data?.idProduto) {
        // Caso de edição: salvar diretamente
        dispatch(editarProduto({ ...formValues, projetoId: idProjetos, nlsPopup }));
      } else {
        // Caso de criação: passar os dados para a confirmação
        if (onConfirmCreate) {
          onConfirmCreate(formValues); // Passa para o popup de confirmação
        }
      }
    }
    
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  
  const handleColar = e => {
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  const handleAddForm = e => {
    dispatch(setMeusProjetosLoad({name: 'produtosAddEdit', value: {...load.produtosAddEdit, add: !load.produtosAddEdit?.add}}))
  }  

  return (
    <Modal
    title={
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {data?.idProduto
          ? nls.configurar?.editProdutoTitle
          : nls.configurar?.addProdutoTitle}
        <PopoverCentralAjuda 
          nlsContexto={nlsContexto} 
          idarray={data?.idProduto ? [133] : [132]} 
        />
      </div>
    }
      size='medium'
      open={statusModal === 'criarProdutoMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.idProduto? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-produtos-add'>
        <div className='colflex col2'>
          <Input
            name='nomeProduto'
            label={nls.configurar?.formNome}
            value={form?.nomeProduto}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            required={{
              erro,
              message: nls.mensagem.obrigatorio,
              pattern: formRequired.nomeProduto
            }}
          />
          <div className='colflex justify'>
            <div className='colflex-100'>
              <Select
                name='grupoProduto'
                label={<>{nls.configurar?.formGrupo} <Popover>Esse e o grupo de produto</Popover></>}
                options={meuProjeto.gruposProdutos}
                selected={form?.grupoProduto?form?.grupoProduto:{}}
                action={(e) => handleChange(e)}
                textCustom={nls.select}
                optionLabel='nomeGrupoProduto'
                optionValue='idGrupoProduto'
                required={{
                  erro,
                  message: nls.mensagem.obrigatorio,
                  pattern: formRequired.grupoProduto
                }}
              />
            </div>
            <div>
              <GruposProdutos
                show={load?.produtosAddEdit?.add}
                id={'add'}
                actionOpen={
                  <Button
                    color='default circle'
                    onClick={()=> handleAddForm()}
                  ><IcoAdd /></Button>
                }
              />
            </div>
          </div>
        </div>
        <div className='colflex col2'>
          <Select
            name='nomeEmpresa'
            label={nls.configurar?.formEmpresa}
            options={meuProjeto.empresas}
            selected={form?.nomeEmpresa?form?.nomeEmpresa:{}}
            action={(e) => data?.idProduto? null : handleChange(e)}
            disabled={data?.idProduto?true:false}
            textCustom={nls.select}
            optionLabel='nomeEmpresa'
            optionValue='empresaId'
            required={{
              erro,
              message: nls.mensagem.obrigatorio,
              pattern: formRequired.nomeEmpresa
            }}
          />
          <Select
            name='subSegmento'
            label={nls.configurar?.formSubSegmento}
            options={meuProjeto.subsegmentos}
            selected={form?.subSegmento?form?.subSegmento:{}}
            action={(e) => data?.idProduto? null : handleChange(e)}
            disabled={data?.idProduto?true:false}
            textCustom={nls.select}
            optionLabel='nomeSubSegmento'
            optionValue='idSubSegmento'
            required={{
              erro,
              message: nls.mensagem.obrigatorio,
              pattern: formRequired.subSegmento
            }}
          />
        </div>
        <div className='colflex'>
          <Input
            name='urlAmazonProduto'
            label={nls.configurar?.formAmazon}
            value={form?.urlAmazonProduto}
            placeholder={nls.configurar?.formAmazonPH}
            action={(e) => handleChange(e)}
            disabled={data?.idProduto && data?.urlAmazonProduto?true:false}
            right={<>
              <span className='btn-colar' onClick={()=> !(data?.idProduto && data?.urlAmazonProduto) ? handleColar('urlAmazonProduto'):null}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
            required={{
              erro,
              message: nls.mensagem.obrigatorio,
              pattern: formRequired.urlAmazonProduto
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
